export const setImageDefault = (event, addClass) => {
  event.target.parentElement.classList.remove('skeleton-box')
  event.target.parentElement.classList.add(`image-default__${addClass}`)

  if (event.target.parentElement.childNodes.length < 2) {
    const message = document.createElement('h1')
    message.innerText = 'this browser is not supported this image'
    event.target.parentElement.appendChild(message)
  }
}
