<template>
  <div
    class="new-main-footer__item__contact"
    itemprop="address"
    itemscope
    itemtype="http://schema.org/PostalAddress"
  >
    <div class="new-main-footer__item__contact__title">
      Contact Us
    </div>
    <div class="new-main-footer__item__contact__body">
      <div class="body-wrapper">
        <div
          v-for="info in contactUs"
          :key="info.title"
          class="body-wrapper__item"
        >
          <div class="body-wrapper__item__icon">
            <img
              :src="info.icon"
              :alt="`Icon Hubungi Kami (${info.title}) Lion Parcel`"
              height="20"
              width="20"
            />
          </div>
          <div class="body-wrapper__item__body">
            <div v-if="info.title !== '' && !info.data">
              <div class="body-wrapper__item__body__title">
                {{ info.title }}
              </div>
              <div
                class="body-wrapper__item__body__desc"
                style="white-space: normal;"
                :itemprop="info.itemprop"
              >
                <span v-html="info.body" />
              </div>
            </div>
            <div
              v-for="dataInfo in info.data"
              v-else
              :key="dataInfo.title"
            >
              <div class="body-wrapper__item__body__title">
                {{ dataInfo.title }}
              </div>
              <div
                style="white-space: normal;"
                :itemprop="dataInfo.itemprop"
              >
                {{ dataInfo.body }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: 'ContactUs',
  components: {
    ImageLazy,
  },
  props: {
    contactUs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
