<template>
  <div id="container-app" hidden>
    <div id="main-nav">
      <NewNavBar />
    </div>
    <div id="app-router-view" :style="styleApp">
      <router-view />
    </div>
    <!-- whatsapp for customer service  -->
    <div
      id="wa-cs"
    >
      <a
        href="https://wa.me/6281119600999"
        rel="noreferrer noopener"
        target="_blank"
      >
        <img
          id="Liona"
          class="liona-image"
          src="/images/Liona.webp"
          alt="whatsapp_liona"
          width="100%"
          height="100%"
        />
      </a>
    </div>
    <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import NewNavBar from './components/new-navbar/Navbar';
import Footer from './components/new-footer/Footer';

export default {
  name: 'App',
  components: {
    Footer,
    NewNavBar,
  },
  metaInfo() {
    if (this.isSsr()) return;
    return {
      title: 'Main page',
    };
  },
  data() {
    return {
      load: false,
      deletedID: false,
      metaInfo: '',
    };
  },
  computed: {
    styleApp() {
      return this.$route.path !== '/'
        ? {
          position: '',
          paddingTop: '36px',
        }
        : {
          position: 'relative',
          paddingTop: '0',
        };
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/css/reset.scss';

#container-app {
  font-family: "Montserrat", sans-serif;
  letter-spacing: -0.2px;
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto;
}

#main-nav {
  height: 0;
  position: fixed;
  width: 100%;
  z-index: 1001;
}

#loader-lp {
  height: 100vh;
}

@keyframes dum {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.1);
  }
}

#animation-on {
  animation: bummer 1s;
  -webkit-animation: bummer 1s;
  -moz-animation: bummer 1s;
  transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@-webkit-keyframes bummer {
  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes bummer {
  100% {
    -moz-transform: scale(1);
  }
}

@keyframes bummer {
  100% {
    transform: scale(1);
  }
}

.app-space {
  padding-top: 2%;
}

@media (min-width: 900px) {
  .app-space {
    padding-top: 4%;
  }
}

#wa-cs {
  position: fixed;
  right: 0 !important;
  bottom: 0;
  padding-right: 10px;
  z-index: 500;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  .liona-image {
      width: 140px;

      @media (max-width: 599px) {
        width: 80px;
      }
    }
  }
</style>
