import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import VueAnalytics from 'vue-analytics';

import { createRouter } from './route/routes';
import { createStore } from './store';

import App from './App.vue';

import SSRDetector from './helpers/DetectSSRMixin';
import SEO from './helpers/SEO';
import MobileOrDesktop from './helpers/MobileOrDesktopMixin';

Vue.mixin(SSRDetector);
Vue.mixin(MobileOrDesktop);
Vue.mixin(SEO);

Vue.config.devtools = process.env.NODE_ENV !== 'production';

export function createApp() {
  const router = createRouter();
  const store = createStore();

  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_TRACKING_GA,
    router,
  });

  sync(store, router);

  const app = new Vue({
    router,
    store,
    render: h => h(App),
  });

  return { app, router, store };
}
