<template>
  <div class="new-main-footer__item__child">
    <div class="new-main-footer__item__child__coat">
      <div class="new-main-footer__item__child__wrapper">
        <div class="new-main-footer__item__child__title">
          Layanan Kami
        </div>
        <div
          v-if="product.length > 0"
          class="sitemap"
        >
          <span
            v-for="nav in product"
            :key="nav.title"
            name="spanProduct"
            @click="onClick(nav.link)"
          >
            {{ nav.title }}
          </span>
        </div>
      </div>
      <div class="new-main-footer__item__child__wrapper">
        <div class="new-main-footer__item__child__title2">
          Bermitra
        </div>
        <div
          v-if="mitra.length > 0"
          class="sitemap"
        >
          <span
            v-for="nav in mitra"
            :key="nav.title"
            name="spanMitra"
            @click="onClick(nav.link)"
          >
            {{ nav.title }}
          </span>
        </div>
      </div>
      <div class="new-main-footer__item__child__wrapper">
        <div class="new-main-footer__item__child__title2">
          Informasi Lain
        </div>
        <div
          v-if="other.length > 0"
          class="sitemap"
        >
          <span
            v-for="nav in other"
            :key="nav.title"
            name="spanOther"
            @click="onClick(nav.link)"
          >
            {{ nav.title }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Lp',
  data() {
    return {
      product: [
        {
          title: 'Pengiriman',
          link: '/product',
        },
        {
          title: 'Fullfilment',
          link: '/lilo',
        },
      ],
      mitra: [
        {
          title: 'Daftar Agen',
          link: '/agen',
        },
        {
          title: 'Daftar Korporasi',
          link: '/korporasi',
        },
        {
          title: 'Daftar Merchant',
          link: '/merchant',
        },
      ],
      other: [
        {
          title: 'FAQ',
          link: '/faq',
        },
        {
          title: 'Dangerous Goods',
          link: '/dangerous-goods',
        },
        {
          title: 'Karantina',
          link: '/karantina',
        },
      ],
    };
  },
  methods: {
    onClick(url) {
      if (url) {
        return this.$router.push(url);
      }
      return null;
    },
  },
};
</script>
