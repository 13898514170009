<template>
  <div class="new-main-footer__item__download">
    <div class="new-main-footer__item__download__title">
      Dapatkan Aplikasinya
    </div>
    <div class="new-main-footer__item__download__icon">
      <div v-if="!isAppleDevice">
        <ImageLazy
          id="Download_Google_Play_Footer"
          :src="`/images/playstore.png`"
          alt="playStore_logo"
          height="40"
          width="130"
          @click="openLink('android')"
          @error="(event) => setErrorImage(event, 'playstore')"
        />
      </div>
      <div>
        <ImageLazy
          id="Download_Appstore_Footer"
          :src="`/images/appstore.png`"
          alt="appstore_logo"
          height="40"
          width="130"
          @click="openLink('ios')"
          @error="(event) => setErrorImage(event, 'appstore')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';

export default {
  name: 'DownloadApp',
  components: {
    ImageLazy,
  },
  data() {
    return {
      link: {
        android: 'https://play.google.com/store/apps/details?id=com.lionparcel.services.consumer&hl=in',
        ios: 'https://apps.apple.com/id/app/lion-parcel/id1455977134',
      },
      isAppleDevice: false,
    };
  },
  mounted() {
    this.isAppleDevice = this.isApple();
  },
  methods: {
    openLink(os) {
      window.open(this.link[os], '_blank');
    },
    setErrorImage(event, addClass) {
      setImageDefault(event, addClass);
    },
    isApple() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator?.userAgentData?.platform)
        || /iPad|iPhone|iPod/.test(navigator.userAgent)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
  },
};
</script>
