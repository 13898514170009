import '../type/checkPrice';
import InternalAPI from '../baseAxios';
import { API_VERSION } from './helper';

/** @borrows InternalAPI */
export default class CheckShippingPriceRepository extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = 'tariff';
    this.endpointProduct = 'v2/product_detail/data';
  }

  /**
     *
     * @param queryString {string}
     * @returns {Promise<{data: ShippingPriceInfo, err: ErrorMapper}>
     */
  async getOneResult(queryString) {
    try {
      /** @type {import('axios').AxiosResponse<Object>} */
      const res = await this.$axios.get(`${API_VERSION}/${this.endpoint}${queryString}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: null, err: null };
    } catch (err) {
      let newErr = 'unknown message';
      if (!err.response || !err.response.data) {
        newErr = 'something went wrong';
      }
      if (err.response && err.response.data.message) {
        newErr = err.response.data.message;
      }
      return { data: null, err: this.setErrorMessage(newErr) };
    }
  }

  async getProducts(inputSearch) {
    try {
      const res = await this.$axios.get(`${this.endpointProduct}?${inputSearch}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data.data.data, err: null };
        }
      }
      return { data: [], err: null };
    } catch (err) {
      return { data: [], err: this.setErrorMessage(err.response.data.desc) };
    }
  }
}
