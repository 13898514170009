import axios from 'axios';

/**
 * @return {import("axios").AxiosInstance}
 */
const instanceAxios = axios.create({
  baseURL: process.env.VUE_APP_DOMAIN_URL,
  headers: {
    Authorization: process.env.VUE_APP_TOKEN_URL,
    'Content-Type': 'application/json',
  },
  timeout: 1000 * (+process.env.VUE_APP_TIMEOUT_BE || 12), // default 12 seconds
  timeoutErrorMessage: 'please check your connection',
});
const backendInstanceAxios = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  timeoutErrorMessage: 'please check your connection',
});

export default class InternalAPI {
  constructor() {
    /** @type {import("axios").AxiosInstance} */
    this.$axios = instanceAxios;
    this.$backAxios = backendInstanceAxios;
  }

  /**
   *
   * @param data {object|null|unknown}
   * @return {string|unknown}
   */
  setErrorMessage(data) {
    if (typeof data === 'object') {
      const getValues = Object.values(data);
      if (getValues.length > 0 && getValues[0] !== '') {
        return data.id || getValues[1] || 'sorry, something went wrong!';
      }
      return 'unknown message obj';
    }

    if (typeof data === 'string') {
      if (data !== '') {
        return data;
      }
      return 'unknown message str';
    }

    return 'unknown message type';
  }
}
