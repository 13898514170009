<template>
  <div id="navbar-wrapper" :style="streamingDecideStyleNavbarWrapper">
    <!-- LP Logo   -->
    <div class="navbar-menu-new">
      <img
        id="logo-navbar"
        :src="streamingDecideLogo"
        alt="lion-parcel-logo"
        height="30"
        width="111"
        @click="goToRoute('/')"
      />
    </div>

    <!-- desktop -->
    <div id="nv-desktop" class="navbar-menu-new">
      <div
        v-for="(key, t) in menu"
        :id="decideIDSelected(key.link)"
        :key="`order-${t}`"
        :class="decideClassNavLinkWrapper(key.icon)"
        :style="decideStyleSelected(key.link, t, false, 'parent')"
      >
        <img
          v-if="key.icon"
          :id="key.icon.id"
          :src="key.icon.desktop"
          :alt="key.icon.alt"
          height="12"
          width="12"
        />
        <!-- dropdown -->
        <div v-if="key.child.length > 0" class="nav__item">
          <input
            :id="`dropdown-menu-${makeID(key.title)}`"
            type="checkbox"
            :value="'Dropdown Menu' + key.title"
            class="dropdown-toggle"
            @click="clickDropDown(makeID(key.title))"
          />
          <label
            :for="`dropdown-menu-${makeID(key.title)}`"
            class="nav__link dropdown-action"
            aria-label="Toggle Dropdown Desktop"
          >
            <span class="dropdown-toggle-text__desktop nav-link-title">{{
              key.title
            }}</span></label
          >
          <ul class="dropdown">
            <li
              v-for="(val, idx) in key.child"
              :key="val.title"
              class="dropdown__item"
              :style="decideStyleSelected(val.link, idx, false, 'child')"
              @click="goToRoute(val.link)"
            >
              <span class="nav__link nav-link-title">{{ val.title }}</span>
            </li>
          </ul>
        </div>
        <!-- end dropdown -->
        <div
          v-if="key.child.length === 0 || !key.child"
          style="cursor: pointer;"
          class="nav-link-title"
          @click="goToRoute(key.link)"
        >
          {{ key.title }}
        </div>
      </div>
    </div>
    <!-- end desktop -->

    <!--  mobile  -->
    <div id="nv-mobile" class="navbar-menu-mobile">
      <input
        id="toggle-action__check"
        type="checkbox"
        value="Menu"
        class="dropdown-toggle"
        @click="changeNav('toggle-action__check')"
      />
      <label
        for="toggle-action__check"
        class="nav__link dropdown-action"
        aria-label="Toggle Menu Mobile"
      >
        <div id="nav-burger" class="toggle-action__line__white" />
      </label>
      <div class="nav-mobile dropdown-mobile">
        <div class="dropdown-mobile__wrapper">
          <span
            v-for="(key, idx) in menuForMobile"
            :key="'menu-' + idx"
            class="dropdown-mobile__item"
          >
            <div v-if="key.child.length > 0" class="nav-link-mobile">
              <img v-if="key.icon" :src="key.icon.mobile" class="nav-login-icon" alt="login.png" />
              <input
                :id="'dropdown-mobile-' + key.title"
                type="checkbox"
                :value="key.title"
                class="dropdown-toggle"
                :checked="isRouteInThisSelected(idx)"
              />
              <label
                :for="'dropdown-mobile-' + key.title"
                aria-label="Toggle Dropdown Mobile"
              >
                <span class="dropdown-toggle__mobile">
                  {{ key.title }}
                </span>
              </label>
              <ul class="dropdown-mobile-ul">
                <li
                  v-for="(val, j) in key.child"
                  :key="val.title"
                  class="dropdown-mobile-ul__item"
                  style="border-radius: 10px;"
                  :style="decideStyleSelected(val.link, j, true)"
                >
                  <span @click="goToRoute(val.link)">{{ val.title }}</span>
                </li>
              </ul>
            </div>
            <div
              v-else
              class="nav-link-mobile"
              :style="decideStyleSelected(key.link, idx, true)"
            >
              <span class="nav__link" @click="goToRoute(key.link)">
                {{ key.title }}
              </span>
            </div>
          </span>
        </div>
      </div>
    </div>
    <!-- end mobile -->
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      TransparentLink: [] /* link that will have transparent navigation background on top position. ex: ["/", "/promo"], must set those pages (banner) with no margin-top/padding-top, also check on page wrapper on App.vue*/,
      classSelected: '',
      base: {
        color: {
          red: 'transparent',
          white: '#FFFFFF',
          dark: '#333333',
        },
        hover: {
          white: '#EBECF0',
          red: '#AD0020',
        },
      },
      toggleMobile: {
        white: {
          color: '#FFFFFF',
          className: 'toggle-action__line__white',
        },
        red: {
          color: 'transparent',
          className: 'toggle-action__line__red',
          classNameScroll: 'toggle-action__line__red__scroll',
        },
      },
      navbarDesktop: {
        white: {
          className: 'dropdown-toggle-text__desktop',
        },
        red: {
          className: 'dropdown-toggle-text__desktop-red',
        },
      },
      navbarID: {
        wrapper: '#navbar-wrapper',
        burger: '#nav-burger',
        logo: '#logo-navbar',
      },
      imgIcon: {
        primary: 'img-icon-nav',
        hoverActive: 'img-icon-nav-hover',
      },
      timeDelay: 0,
      isUserAgentMobile: false,
    };
  },
  computed: {
    menu() {
      return [
        {
          title: 'Layanan Kami',
          link: '',
          child: [
            {
              title: 'Pengiriman',
              link: '/product',
            },
            {
              title: 'Fulfillment',
              link: '/lilo',
            },
            {
              title: 'COD',
              link: '/cod',
            },
          ],
        },
        {
          title: 'Promo',
          link: '/promo',
          child: [],
        },
        {
          title: 'Event & Tips',
          link: '',
          child: [
            {
              title: 'Info Mitra',
              link: '/info-mitra',
            },
            {
              title: 'Info Seller',
              link: '/info-seller',
            },
          ],
        },
        {
          title: 'Bermitra',
          link: '',
          child: [
            {
              title: 'Daftar Agen',
              link: '/agen',
            },
            {
              title: 'Daftar Korporasi',
              link: '/korporasi',
            },
            {
              title: 'Daftar Merchant',
              link: '/merchant',
            },
          ],
        },
        {
          title: 'Informasi Lain',
          link: '',
          child: [
            {
              title: 'FAQ',
              link: '/faq',
            },
            {
              title: 'Dangerous Goods',
              link: '/dangerous-goods',
            },
            {
              title: 'Karantina',
              link: '/karantina',
            },
          ],
        },
        {
          title: 'Klaim',
          link: '/klaim',
          child: [],
        },
        {
          title: 'Dashboard Pengiriman',
          link: '',
          child: [
            {
              title: 'Daftar',
              link: '/register',
            },
            {
              title: 'Masuk',
              link: '/login',
            },
          ],
          icon: {
            alt: 'login',
            id: 'ic-login-logo',
            mobile: '/images/login_navbar_gray_d.svg',
            desktop: '/images/login_navbar.svg',
            onScroll: '/images/login_navbar_gray_d.svg',
          },
        },
      ];
    },
    isTransparentBase() {
      return this.TransparentLink.includes(this.$route.path)
    },
    streamingDecideStyleNavbarWrapper() {
      return this.decideStyleNavbarWrapper();
    },
    streamingDecideLogo() {
      return this.decideLogo();
    },
    menuForMobile() {
      if (this.isUserAgentMobile) {
        return this.menu.slice(0, this.menu.length - 1);
      }
      return this.menu;
    },
  },
  mounted() {
    this.isNavigatorMobile();
    this.decideLogo();
    this.decideStyleNavbarWrapper();
  },
  created() {
    if (!this.isSsr()) {
      window.addEventListener('scroll', this.changeBGNavbar, { passive: true });
      window.addEventListener('mouseover', this.changeMouseOverIconNav, {
        passive: true,
      });
      window.addEventListener('mouseout', this.changeMouseOutIconNav, {
        passive: true,
      });
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.changeBGNavbar);
    window.removeEventListener('mouseover', this.changeMouseOverIconNav);
    window.removeEventListener('mouseout', this.changeMouseOutIconNav);
    clearTimeout(this.timeDelay);
  },
  methods: {
    decideLogo() {
      return this.isTransparentBase ? '/images/lp_logo_white.svg' : '/images/lp_logo_red.svg';
    },
    decideStyleNavbarWrapper() {
      const red = `background-color: ${this.base.color.red};`;
      const white = `background-color: ${this.base.color.white};`;
      if (this.isTransparentBase) {
        this.changeColorTextNavDesktop(false);
        return red;
      } else {
        this.changeColorBurgerMobile(true);
        this.changeColorTextNavDesktop(true);
        return white;
      }
    },
    decideClassNavLinkWrapper(keyIcon) {
      const icon = 'img-icon-nav';
      let newClass = 'nav-link-new ';
      if (keyIcon) {
        newClass += icon;
      }
      return newClass;
    },
    /**
     *
     * @param id {string} id name of input checkbox dropdown burger
     */
    changeNav(id) {
      if (!this.isSsr()) {
        const isMobileNavbarChecked = this.$el.querySelector(`#${id}`).checked;
        const getBaseNav = document.querySelector(this.navbarID.wrapper);
        const navBurger = document.querySelector(this.navbarID.burger);
        const logoNavbar = document.querySelector(this.navbarID.logo);
        if (this.timeDelay) {
          clearTimeout(this.timeDelay);
        }
        if (isMobileNavbarChecked) {
          navBurger.classList.remove(this.toggleMobile.white.className);
          navBurger.classList.add(this.toggleMobile.red.className);
          getBaseNav.style.backgroundColor = this.toggleMobile.white.color;
          logoNavbar.src = '/images/lp_logo_red.svg';
        } else {
          if (this.isTransparentBase) {
            logoNavbar.src = '/images/lp_logo_white.svg';
            navBurger.classList.add(this.toggleMobile.white.className);
            navBurger.classList.remove(this.toggleMobile.red.className);
            getBaseNav.style.backgroundColor = this.toggleMobile.red.color;
          }
        }
        this.changeBGNavbar();
      }
    },
    /**
     * if icon on navbar many?
     * you can create array to loop each ids of icons
     * @param isForScroll {boolean}
     * */
    changeIconLogin(isForScroll) {
      if (!this.isSsr()) {
        const elIMG = document.querySelector('#ic-login-logo');
        if (elIMG) {
          if (isForScroll) {
            if (elIMG.classList.contains(this.imgIcon.hoverActive)) {
              elIMG.src = '/images/login_navbar.svg';
            } else {
              elIMG.src = '/images/login_navbar_gray_d.svg';
            }
          } else {
            elIMG.src = '/images/login_navbar.svg';
          }
        }
      }
    },
    /** @param isForScroll {boolean} */
    changeArrowNavDesktop(isForScroll) {
      if (!this.isSsr()) {
        const iconArrowNav = document.querySelectorAll(
          '[aria-label="Toggle Dropdown Desktop"] span',
        );
        if (iconArrowNav && iconArrowNav.length > 0) {
          if (isForScroll) {
            iconArrowNav.forEach(span => {
              span.classList.remove(this.navbarDesktop.white.className);
              span.classList.add(this.navbarDesktop.red.className);
            });
          } else {
            iconArrowNav.forEach(span => {
              span.classList.remove(this.navbarDesktop.red.className);
              span.classList.add(this.navbarDesktop.white.className);
            });
          }
        }
      }
    },
    /** @param isForScroll {boolean} */
    changeColorTextNavDesktop(isForScroll) {
      if (!this.isSsr()) {
        const navbarDesktopChild = document.querySelectorAll(
          '.navbar-menu-new div',
        );
        const navbarDesktopParent = document.querySelectorAll('.nav-link-new');
        if (
          navbarDesktopChild &&
          navbarDesktopChild.length > 0 &&
          navbarDesktopParent &&
          navbarDesktopParent.length > 0
        ) {
          if (isForScroll) {
            navbarDesktopParent.forEach(div => {
              div.classList.remove('navbar-menu-new-hover');
              div.classList.add('navbar-menu-new-hover-color');
            });
            this.changeArrowNavDesktop(true);
            this.changeIconLogin(true);
          } else {
            navbarDesktopParent.forEach(div => {
              div.classList.remove('navbar-menu-new-hover-color');
              div.classList.add('navbar-menu-new-hover');
            });
            this.changeArrowNavDesktop(false);
            this.changeIconLogin(false);
          }
        }
      }
    },
    /** @param isForScroll {boolean} */
    changeColorBurgerMobile(isForScroll) {
      if (!this.isSsr()) {
        const navbarBurger = document.querySelector(this.navbarID.burger);
        if (navbarBurger) {
          if (isForScroll) {
            navbarBurger.classList.remove(this.toggleMobile.white.className);
            navbarBurger.classList.add(this.toggleMobile.red.classNameScroll);
          } else {
            navbarBurger.classList.remove(
              this.toggleMobile.red.classNameScroll,
            );
            navbarBurger.classList.add(this.toggleMobile.white.className);
          }
        }
      }
    },
    /** @param color {string} */
    changeSelectedRoute(color) {
      if (!this.isSsr()) {
        const linkSelected = document.querySelector('#link-selected');
        if (linkSelected) {
          linkSelected.style.backgroundColor = this.base.hover[color];
        }
      }
    },
    /** @param identifier {string} */
    changeBaseNavbar(identifier) {
      if (!this.isSsr()) {
        const navbar = document.querySelector(this.navbarID.wrapper);
        const logoNavbar = document.querySelector(this.navbarID.logo);

        if (identifier === 'white') {
          this.changeSelectedRoute(identifier);
          navbar.style.backgroundColor = this.toggleMobile.white.color;
          logoNavbar.src = '/images/lp_logo_red.svg';
          if (!['Agen'].includes(this.$route.name)) {
            navbar.style.boxShadow = '0px 4px 25px rgba(0, 0, 0, 0.1)';
          }
          this.changeColorBurgerMobile(true);
          this.changeColorTextNavDesktop(true);
        } else {
          logoNavbar.src = '/images/lp_logo_white.svg';
          navbar.style.backgroundColor = this.toggleMobile.red.color;
          this.changeColorBurgerMobile(false);
          this.changeColorTextNavDesktop(false);
          navbar.style.boxShadow = 'none';
          this.changeSelectedRoute(identifier);
        }
      }
    },
    homepageBurger() {
      if (!this.isSsr()) {
        const navBurger = document.querySelector(this.navbarID.burger);
        if (navBurger) {
          navBurger.classList.remove(this.toggleMobile.red.classNameScroll);
          navBurger.classList.remove(this.toggleMobile.red.className);
          navBurger.classList.add(this.toggleMobile.white.className);
        }
      }
    },
    changeBGNavbar() {
      const isMobileNavbarChecked = this.$el.querySelector(
        `#toggle-action__check`,
      ).checked;
      const appIDInVueApp = document.querySelector('#animation-on');
      if (appIDInVueApp) {
        appIDInVueApp.id = '';
      }
      if (window.scrollY > 50 && !isMobileNavbarChecked) {
        this.changeBaseNavbar('white');
      } else {
        if (!isMobileNavbarChecked) {
          if (this.isTransparentBase) {
            this.changeBaseNavbar('red');
            this.homepageBurger();
          } else {
            const navbar = document.querySelector(this.navbarID.wrapper);
            navbar.style.boxShadow = 'none';
          }
        }
      }
    },
    changeMouseOverIconNav() {
      if (!this.isSsr()) {
        const imgHoverActive = this.imgIcon.hoverActive;
        const img = document.querySelector('.' + this.imgIcon.primary);
        if (img) {
          img.onmouseover = function() {
            this.firstChild.classList.add(imgHoverActive);
          };
        }
      }
    },
    changeMouseOutIconNav() {
      const imgHoverActive = this.imgIcon.hoverActive;
      const img = document.querySelector('.' + this.imgIcon.primary);
      const navbarWrapper = document.querySelector(this.navbarID.wrapper);
      if (img) {
        const base = this.base;
        img.onmouseout = function() {
          this.firstChild.classList.remove(imgHoverActive);
          if (navbarWrapper) {
            if (navbarWrapper.style.backgroundColor !== base.color.red) {
              this.firstChild.src = '/images/login_navbar_gray_d.svg';
            }
          }
        };
      }
    },
    closeDropDown() {
      const isChecked = this.$el.querySelectorAll('.dropdown-toggle');
      if (isChecked.length && isChecked.length > 0) {
        isChecked.forEach(el => {
          if (el.checked) {
            el.checked = false;
          }
        });
      }
    },
    /** @param link {string}
     * @param isMobile {boolean} */
    goToRoute(link) {
      this.closeDropDown();
      if (link === '/login') {
        window.open(process.env.VUE_APP_AKUN_DOMAIN_URL);
      } else {
        if (this.$route.path !== link) {
          this.$router.push(link);
        }
        else {
          this.changeBGNavbar()
        }
      }
    },
    /** @param link {string} */
    decideIDSelected(link) {
      return this.$route.path === link ? 'link-selected' : '';
    },
    /** @param link {string}
     * @param index {number}
     * @param isMobile {boolean}
     * */
    decideStyleSelected(link, index, isMobile = false, status = undefined) {
      const red = `background-color: ${this.base.hover.red};`;
      const white = `background-color: ${this.base.hover.white};`;
      let someInclude = false;

      if (
        status === 'parent' &&
        this.menu[index].child &&
        this.menu[index].child.length > 0
      ) {
        someInclude = this.menu[index].child.some(
          v => v.link === this.$route.path,
        );
      }

      if (this.$route.path === link || someInclude) {
        if (isMobile) {
          return white;
        }
        if (this.isTransparentBase) {
          return red;
        } else {
          return white;
        }
      }
    },
    isRouteInThisSelected(index) {
      if (
        this.menuForMobile[index].child &&
        this.menuForMobile[index].child.length > 0
      ) {
        return this.menuForMobile[index].child.some(
          v => v.link === this.$route.path,
        );
      }
      return false;
    },
    isNavigatorMobile() {
      this.isUserAgentMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      );
    },
    makeID(title) {
      return title
        .split(' ')
        .join('_')
        .toLowerCase();
    },
    clickDropDown(title) {
      const dropdowns = this.$el.querySelectorAll(`.dropdown-toggle`);
      for (const dropdown of dropdowns) {
        if (dropdown.checked) {
          if (dropdown.id !== `dropdown-menu-${title}`) {
            dropdown.checked = false;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
.nav-login-icon {
  width: 12;
  height: 16;
}
</style>
