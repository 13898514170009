<template>
  <div>
    <div class="new-main-footer">
      <!-- DESKTOP -->
      <div
        v-if="!isMobileSize"
        class="new-main-footer__item"
      >
        <SocialMedia />
        <Lp />
        <Info />
        <ContactUs :contact-us="contactUs" />
        <DownloadApp />
      </div>
      <!-- END DESKTOP -->

      <!-- MOBILE -->
      <div
        v-if="isMobileSize"
        class="new-main-footer__item"
      >
        <SocialMedia />
        <div>
          <Lp />
          <Info />
        </div>
        <ContactUs :contact-us="contactUs" />
        <DownloadApp v-if="isMobileSize" />
      </div>
      <!-- END MOBILE -->
    </div>
    <div class="end-footer">
      <hr>
      <div class="end-footer__inside">
        @ {{ yearFirst }}-{{ yearNow }} All Rights Reserved - Lion Parcel
      </div>
    </div>
  </div>
</template>

<script>
import Lp from './Lp';
import ContactUs from './ContactUs';
import Info from './Info';
import SocialMedia from './SocialMedia';
import DownloadApp from './DownloadApp';

import mixinMobile from '../../misc/mixinMobile';

export default {
  name: 'Footer',
  components: {
    DownloadApp,
    ContactUs,
    Lp,
    Info,
    SocialMedia,
  },
  mixins: [mixinMobile],
  data() {
    return {
      yearFirst: 2020,
      yearNow: new Date().getFullYear(),
      contactUs: [
        {
          icon: '/images/footer_address.svg',
          title: 'Head Office',
          body: 'Jl. Agave Raya No. 55, Kedoya Selatan, Jakarta Barat, 11520',
          data: null,
          itemprop: 'streetAddress',
        },
        {
          icon: '/images/footer_chat.svg',
          title: 'Customer Service',
          body: '+62-21-80820072',
          data: null,
          itemprop: 'telephone',
        },
        {
          icon: '/images/footer_mail.svg',
          title: 'Mitra',
          body: 'help@thelionparcel.com',
          data: null,
          itemprop: 'email',
        },
        {
          icon: '/images/footer_clock.svg',
          title: 'Monday to Sunday',
          body: '07:00 AM to 23:00 PM',
          data: null,
          itemprop: 'schedule',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "./style.scss";
</style>
