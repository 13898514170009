<template>
  <div v-lazyload>
    <img
      :id="`${generateId}`"
      :data-url="src"
      :alt="alt"
      :class="className"
      rel="preload"
      :width="width"
      :height="height"
      @click="$emit('click')"
      @error="(event) => $emit('error', event)"
      @touchstart="(event) => $emit('touchstart', event)"
      @touchend="(event) => $emit('touchend', event)"
    >
  </div>
</template>

<script>
import lazyLoad from '@/helpers/Lazyload';

export default {
  name: 'ImageLazy',
  directives: {
    lazyload: lazyLoad,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      required: true,
      default: '',
    },
    alt: {
      type: String,
      required: true,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
  },
  data() {
    return {
      generateId: null,
    };
  },
  watch: {
    src(val) {
      if (!this.isSsr()) {
        const img = document.getElementById(`${this.generateId}`);
        if (img && img.src) {
          img.src = val;
        }
      }
    },
  },
  created() {
    this.makeid(5);
  },
  methods: {
    makeid(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        );
      }
      this.generateId = this.id
        ? this.id
        : result;
    },
  },
};
</script>
