import '../type/index';
import InternalAPI from '../baseAxios';

/** @borrows InternalAPI */
export default class TrackRepository extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = 'track/data?q=';
  }

  /**
     *
     * @param listStt {string}
     * @returns {Promise<{data: TrackResult[], err: ErrorMapper}>
     */
  async getAll(listStt) {
    const API_VERSION = (process.env.VUE_APP_IS_GENESIS === 'true') ? 'v2' : 'v1';
    try {
      /** @type {AxiosResponse<Object>} */
      const res = await this.$axios.get(`${API_VERSION}/${this.endpoint}${listStt}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data.data, err: null };
        }
      }
      return { data: [], err: null };
    } catch (err) {
      let newErr = err.message;
      if (!err.response || !err.response.data) {
        newErr = err.message;
      }
      return { data: [], err: this.setErrorMessage(newErr) };
    }
  }
}
