<template>
  <div class="new-main-footer__item__child">
    <div class="new-main-footer__item__child__wrapper">
      <div class="new-main-footer__item__child__title">
        Lion Parcel
      </div>
      <div
        v-if="article.length > 0"
        class="sitemap"
      >
        <span
          v-for="nav in article"
          :key="nav.title"
          name="spanArticle"
          @click="onClick(nav.link)"
        >
          {{ nav.title }}
        </span>
      </div>
    </div>
    <div class="new-main-footer__item__child__wrapper">
      <div class="new-main-footer__item__child__title2">
        Tentang Kami
      </div>
      <div
        v-if="about.length > 0"
        class="sitemap"
      >
        <span
          v-for="nav in about"
          :key="nav.title"
          name="spanAbout"
          @click="onClick(nav.link)"
        >
          {{ nav.title }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Lp',
  data() {
    return {
      article: [
        {
          title: 'Promo',
          link: '/promo',
        },
        {
          title: 'Event & Tips',
          link: '/info-mitra',
        },
      ],
      about: [
        {
          title: 'Profil Perusahaan',
          link: '/about',
        },
        {
          title: 'Karir',
          link: '/career',
        },
      ],
    };
  },
  methods: {
    onClick(url) {
      if (url) {
        return this.$router.push(url);
      }
      return null;
    },
  },
};
</script>
