import CheckShippingPriceRepository from '../api/checkShippingPrice';

class CheckShippingPriceUseCase {
  constructor() {
    /** @type {string[]} */
    this.product_details = [];
    this.inactive_products = [];
    this.api = new CheckShippingPriceRepository();
  }

  /**
     *
     * @param resultCheckTarif {ShippingPriceInfo|null|undefined}
     * @return {ShippingPriceInfo| []}
     */
  async manipulationDataCheckPrice(resultCheckTarif) {
    this.inactive_products = [];
    let isNotInternationalShipping = true;
    if (resultCheckTarif && resultCheckTarif.result.length > 0) {
      const listProduct = resultCheckTarif.result.map(v => v.product.toLowerCase());
      await this.constructProduct(listProduct);
      for (const val of resultCheckTarif.result) {
        const productDesign = this._getProduct(val.product);
        if (productDesign) {
          val.design = productDesign
          const currentEstimation = productDesign.text_estimation.match(/\d|-/g)
          if (val.ETD) {
            val.ETD = this._deleteETDOfDay(val.ETD)
          } else if ((val.ETD === "" || val.ETD === "-") && currentEstimation.length > 0) {
            val.ETD = currentEstimation.join("")
          }
          if (val.product.toLowerCase() === "interpack") {
            isNotInternationalShipping = false
          }
        }
      }
    }
    resultCheckTarif.isNotInternationalShipping = isNotInternationalShipping;
    // ignore if product design not found
    resultCheckTarif.result = resultCheckTarif?.result.filter(v => v.design);
    // filter inactive products
    resultCheckTarif.result = resultCheckTarif?.result.filter(v => !this.inactive_products.includes(v.product));
    // sorting the product by id
    resultCheckTarif.result = resultCheckTarif?.result.sort((a,b) => a.design.product_id - b.design.product_id);
    return resultCheckTarif || [];
  }

  /**
     *
     * @param etd {string} // estimation shipping
     * @return {string|*}
     * @private
     */
  _deleteETDOfDay(etd) {
    const rgxAlphabet = /[a-zA-Z]/g;
    if (etd && etd !== '') {
      return rgxAlphabet.test(etd) ? etd.replace(rgxAlphabet, '') : etd;
    }
    return etd || '';
  }

  _getProduct(name) {
    return this.product_details.find(v => v.service_name.toLowerCase() === name.toLowerCase());
  }

  // recursive function to keep try to search until not found
  async constructProduct(listProduct, page = 1, finalResult = []) {
    if (listProduct.length === 0) {
      this.product_details = finalResult;
      return finalResult;
    }
    const q = `status=&page=${page}&per_page=10`;

    try {
      const res = await this.api.getProducts(q);
      if (listProduct.length === 0 || res.data.length === 0) {
        this.product_details = finalResult;
        return finalResult;
      }
      for (const r of res.data) {
        const isContain = listProduct.indexOf(r.service_name.toLowerCase());
        const isInactive = r.status === 'INACTIVE';
        if (isContain !== -1) {
          finalResult.push(r);
          listProduct.splice(isContain, 1);
        }
        if (isInactive) {
          this.inactive_products.push(r.service_name);
        }
      }
      await this.constructProduct(listProduct, page + 1, finalResult);
    } catch (err) {
      return new Error(err);
    }
  }
}

export default new CheckShippingPriceUseCase();
