<template>
  <div class="new-main-footer__item__social-media">
    <img
      id="logo-footer"
      :src="footerLogo"
      alt="lion-parcel-logo-footer"
      height="40"
      width="170"
      style="cursor: pointer;"
      @click="$router.push('/')"
    >
    <div class="text-address">
      <span>Jl. Agave Raya No. 55, </span>
      <span>Kedoya Selatan, Jakarta Barat, 11520</span>
    </div>
    <div class="new-main-footer__item__social-media__link">
      <a
        v-for="sm in socialMedia"
        ref="media-icon"
        :key="sm.link"
        style="cursor: pointer;"
        :href="sm.link"
        rel="noreferrer noopener"
        target="_blank"
      >
        <ImageLazy
          id="social-media-icon"
          :src="sm.icon"
          :alt="sm.alt"
          :title="sm.title"
          height="36"
          width="36"
        />
      </a>
    </div>
  </div>
</template>

<script>
import ImageLazy from '@/components/ImageLazy/index.vue';
import mixinMobile from '../../misc/mixinMobile';

export default {
  name: 'SocialMedia',
  mixins: [mixinMobile],
  components: {
    ImageLazy,
  },
  data() {
    return {
      footerLogo: '/images/lp_logo_footer.svg',
      socialMedia: [
        {
          icon: '/images/sm_insta.svg',
          alt: 'instagram',
          title: 'Instagram',
          link: 'https://www.instagram.com/lionparcelid/?hl=id',
        },
        {
          icon: '/images/sm_twitter.svg',
          alt: 'twitter',
          title: 'Twitter',
          link: 'https://twitter.com/lionparcel_',
        },
        {
          icon: '/images/sm_facebook.svg',
          alt: 'facebook',
          title: 'Facebook',
          link: 'https://web.facebook.com/lionparcelid/',
        },
        {
          icon: '/images/sm_youtube.svg',
          alt: 'youtube',
          title: 'YouTube',
          link:
            'https://www.youtube.com/channel/UCFpSdUfuyHJPsiIE99mCnyg/featured',
        },
        {
          icon: '/images/sm_linkedin.svg',
          alt: 'linkedin',
          title: 'LinkedIn',
          link: 'https://id.linkedin.com/company/lion-parcel',
        },
        {
          icon: '/images/sm_tiktok.svg',
          alt: 'tiktok',
          title: 'TikTok',
          link: 'https://www.tiktok.com/@lionparcelid?lang=en',
        },
      ],
    };
  },
  methods: {},
};
</script>
